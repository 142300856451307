@font-face {
    font-family: 'Telegraf-UltraLight';
    src: url('https://snplwidget.s3.ap-south-1.amazonaws.com/Telegraf-UltraLight.otf');
}

@font-face {
    font-family: 'Telegraf-Regular';
    src: url('https://snplwidget.s3.ap-south-1.amazonaws.com/Telegraf-Regular.otf');
}

@font-face {
    font-family: 'Saathi-UltraBold';
    src: url('https://snplwidget.s3.ap-south-1.amazonaws.com/Saathi-UltraBold.otf');
}

.App{
    overflow: hidden;
    flex: 1;
}

h1,
h2,
h3,
h4,
h5,
.saathi-font {
    font-family: 'Saathi-UltraBold' !important;
    font-weight: 800;
}

p {
    font-family: 'Telegraf-Regular';
}

button {
    font-family: 'Saathi-UltraBold';
}

.highcharts-title, text {
    font-family: 'Telegraf-Regular';
}